@font-face {
  font-family: 'Open-Sans-SemiBold';
  src: url(/fonts/OpenSans-SemiBold.ttf);
}

@font-face {
  font-family: 'Open-Sans-Bold';
  src: url(/fonts/OpenSans-Bold.ttf);
}

@font-face {
  font-family: 'Lato';
  src: url(/fonts/Lato-Regular.ttf);
}

@font-face {
  font-family: 'Lato-Bold';
  src: url(/fonts/Lato-Bold.ttf);
}

@font-face {
  font-family: 'Jost-Regular';
  src: url(/fonts/Jost-Regular.ttf);
}

